import React from 'react';
import ReactDOM from 'react-dom';
import FilterApp from "./Modules/filter/containers/filter-app";
import ProductDetailSliderApp from "./Modules/product-detail-slider/containers/product-detail-slider-app";
import StepApp from "./Modules/steps/containers/step-app";

let root = document.getElementById('login');
if (root) {
    ReactDOM.render(<FilterApp/>, root);
}

let productDetailSlider = document.getElementById('product-detail-slider');
if (productDetailSlider) {
    ReactDOM.render(<ProductDetailSliderApp {...(productDetailSlider.dataset)} />, productDetailSlider);
}


let steps = document.getElementsByClassName('step-tile-wrapper');
if (steps.length) {
    for (let i = 0; i < steps.length; i++) {
        ReactDOM.render(<StepApp {...(steps[i].dataset)} />, steps[i]);
    }
}
