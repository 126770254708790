import React, {useContext, useEffect, useRef, useState} from 'react';
import {ModalContext} from '../context/modelProvider';
import ReactImageMagnify from "react-image-magnify";
import PositiveSpaceLens from "./lens";


/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return {width: srcWidth * ratio, height: srcHeight * ratio};
}

function getMeta(url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = function () {
        callback(this.width, this.height);
    }
}

export default function HeadImage({src}) {

    if (!src) {
        return <div></div>;
    }

    let boxRef = useRef(null);
    let modalRef = useRef(null);

    let defaultDimensions = {
        width: 0,
        height: 0,
    };
    const [imageDimensions, setImageDimensions] = useState();

    const [isModal, setIsModal] = useContext(ModalContext);
    const [width, setWidth] = useState(0);

    let thumb = src['image'];
    let type = src['type'];
    let content = null;

    useEffect(() => {

        const updateImageDimensions = () => {
            setImageDimensions(defaultDimensions);
            var img = new Image();
            img.src = thumb;
            img.onload = function () {
                setImageDimensions({
                    width: this.width,
                    height: this.height,
                })
            }
        };
        updateImageDimensions();

        const updateWindowDimensions = () => {
            if (boxRef.current) {
                setWidth(boxRef.current.clientWidth);
            }
        };
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, [isModal, src]);

    if (isModal) {
        let modelContent = null;
        if (type === 'video') {
            let iframeRatio = calculateAspectRatioFit(1920, 1080, modalRef.current.clientWidth, modalRef.current.clientHeight);
            modelContent = <iframe src={"https://www.youtube.com/embed/" + src.id} frameBorder="0" style={iframeRatio}/>
        } else {
            modelContent = <img src={thumb} alt="" className="image__fluid border-s_grey mb-5 d-inline-block "/>;
        }
        return <div style={{height: "100%"}} ref={modalRef}>{modelContent}</div>
    }


    if (type === 'video') {
        content = (
            <div className="article-image__box">
                <div className="article-image__box__content">
                    <div className="article-image__body">
                        <div className="article-image__body--image">
                            <div style={{width: width}}>
                                <div className="iframe--aspect">
                                    <iframe src={"https://www.youtube.com/embed/" + src.id} frameBorder="0"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        let imageContent = null;

        let aspect = defaultDimensions;

        if (boxRef.current && imageDimensions.width) {
            aspect = calculateAspectRatioFit(imageDimensions.width, imageDimensions.height, boxRef.current.clientWidth, boxRef.current.clientHeight);
        }
        if (window.innerWidth > 960) {
            imageContent = (
                <ReactImageMagnify {...{
                    smallImage: {
                        width: aspect.width,
                        height: aspect.height,
                        src: thumb
                    },
                    largeImage: {
                        src: thumb,
                        width: 1600,
                        height: 1600
                    },
                    shouldUsePositiveSpaceLens: true,
                    lensComponent: PositiveSpaceLens,
                    enlargedImageContainerClassName: 'magnify',
                    enlargedImageContainerDimensions: {width: '75%', height: '75%'}
                }}/>
            )
        } else {
            imageContent = (
                <div>
                    <img src={thumb} alt="" className="article-image__image" style={{
                        width: aspect.width,
                        height: aspect.height,
                    }}/>
                </div>
            )
        }
        content = (
            <div className="article-image__box image-box-wrapper" onClick={() => setIsModal(true)}>
                <div className="article-image__box__content">
                    <div className="article-image__body">
                        <div className="article-image__body--image">
                            {imageContent}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="article-image" ref={boxRef}>
            {content}
        </div>
    )

}
