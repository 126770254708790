import React, {useEffect, useRef, useState} from 'react';

export default function StepApp({stepId, stepContent, hasImage}) {
    const wrapper = useRef(null);
    const content = useRef(null);

    const [showMore, setShowMore] = useState(false);
    const [showAll, setShowAll] = useState(false);


    const calculateReadMore = () => {
        setShowMore(content.current.getBoundingClientRect().height > wrapper.current.getBoundingClientRect().height)
    };

    useEffect(() => {
        calculateReadMore();
        window.addEventListener('resize', calculateReadMore)
    }, [wrapper, content]);


    return (
        <React.Fragment>
            <div className="posr">
                <div className={"step-tile " + (hasImage ? 'step-tile--image' : '')}>
                    <div className={"row h-100 " + (showMore ? '' : 'align-items-center')}>
                        <div className="col-step-number">
                            <div className={"step-number " + (showMore ? 'step-number--transform' : '')}>
                                {stepId}
                            </div>
                        </div>
                        <div className="col-step-content">
                            <React.Fragment>
                                <div className={"read-more " + (showMore ? 'read-more--active' : '')} ref={wrapper}>
                                    <div ref={content}>
                                        {stepContent}
                                    </div>
                                </div>
                                {showMore &&
                                <div className={"readmore-button"} onClick={() => setShowAll(true)}>
                                    Lees meer
                                </div>}
                            </React.Fragment>

                        </div>
                    </div>
                </div>
                {showAll && <div className={"step-tile step-tile--overlay " + (hasImage ? 'step-tile--image' : '')}>
                    <div className="row">
                        <div className="col-step-number">
                            <div className={"step-number " + (showMore ? 'step-number--transform' : '')}>
                                {stepId}
                            </div>
                        </div>

                        <div className="col-step-content">
                            {stepContent}
                            <div className={"readmore-button readmore-button--active"}
                                 onClick={() => setShowAll(false)}>
                                Terug klappen
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </React.Fragment>
    );
}
