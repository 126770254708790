import React, {useEffect, useState} from "react";

export const ModalContext = React.createContext([]);


export function ModalProvider(props) {
    let defaultUserContext = useState(false);
    const [isModal, setIsModal] = defaultUserContext;
    useEffect(() => {
        isModal ? document.body.classList.add('is-locked') : document.body.classList.remove('is-locked')
    }, [isModal]);

    return (
        <div>
            <ModalContext.Provider value={defaultUserContext}>
                <div>
                    {props.children}
                </div>
            </ModalContext.Provider>
        </div>
    )
}
