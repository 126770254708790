import React, {useContext, useEffect, useState} from 'react';
import SwiperCore, {Controller, Thumbs} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ImageUrl} from "../../../Helpers/Url";
import HeadImage from "../components/headimage";
import {ModalContext} from "../context/modelProvider";

SwiperCore.use([Controller, Thumbs]);
let classNames = require('classnames');

export default function Slider(props) {
    let images = JSON.parse(props.images);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiper, setSwiper] = useState(null);
    const [activeImage, setActiveImage] = useState(null);
    const [isModal, setIsModal] = useContext(ModalContext);
    const onNext = () => {
        if (thumbsSwiper && swiper) {
            swiper.isEnd ? swiper.slideTo(0) : swiper.slideNext();
            updateSwiper();
        }
    };

    const onPrev = () => {
        if (thumbsSwiper && swiper) {
            swiper.isBeginning ? swiper.slideTo(images.length - 1) : swiper.slidePrev();
            updateSwiper();
        }
    };

    const updateSwiper = () => {
        thumbsSwiper.update();
        ShowImageOfIndex();
    };

    const ShowImageOfIndex = () => {
        if (swiper) {
            setActiveImage(images[swiper.activeIndex]);
        }
    };

    useEffect(ShowImageOfIndex, [swiper]);

    return (
        <React.Fragment>
            <div className={isModal ? 'modal-view' : ''}>
                {isModal && <div className="modal-cross" onClick={() => setIsModal(false)}>
                    <div className="cross"/>
                </div>}
                <div className="image-box text-center">
                    <HeadImage src={activeImage}/>
                </div>

                {/*this swiper is nessesary for control is not shown in view */}
                <Swiper
                    thumbs={{swiper: thumbsSwiper}}
                    onSwiper={setSwiper}
                    slidesPerView={1}
                >
                    {images.map((item, index) => {
                        return (
                            <SwiperSlide key={item + index}/>
                        )
                    })}

                </Swiper>

                <div className={"swiper-thumbs"}>

                    <div className="swiper-controller">
                        <div className="swiper-controller__left" onClick={onPrev}>
                            <img src={ImageUrl('icons/product-swiper/left.svg')} alt=""/>
                        </div>
                        <div className="swiper-controller__right" onClick={onNext}>
                            <img src={ImageUrl('icons/product-swiper/right.svg')} alt=""/>
                        </div>
                    </div>

                    <Swiper
                        onSwiper={setThumbsSwiper}
                        slidesPerView={isModal ? 'auto' : 3}
                        spaceBetween={30}
                        draggable={false}
                        allowTouchMove={false}
                        watchSlidesVisibility={true}
                    >
                        {images.map((item, index) => {
                            let thumb = item['image'];

                            let t = classNames('image-middle', {
                                'image-middle--video': item['type'] === 'video'
                            });
                            return (
                                <SwiperSlide key={thumb + index}>
                                    <div className="card-square">
                                        <div className="card-square__body">
                                            <div
                                                className={t}>
                                                <img src={thumb} alt="" className="image__fluid"
                                                     onClick={() => updateSwiper()}/>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
            {isModal && <div className="modal-underlay" onClick={() => setIsModal(false)}/>}
        </React.Fragment>

    );
}
