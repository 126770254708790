import React from 'react';
import SwiperCore, {Controller, Thumbs} from 'swiper';
import {ModalProvider} from "../context/modelProvider";
import Slider from "./slider";

SwiperCore.use([Controller, Thumbs]);

export default function ProductDetailSliderApp({images}) {

    return (
        <React.Fragment>
            <ModalProvider>
                <Slider images={images}/>
            </ModalProvider>
        </React.Fragment>
    );
}
